<template>
    <div>
        <!-- banner wrapper -->
        <div class="banner-wraper text-center pt-4" style="">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-left">
                        <div class="badge badge-danger" style="font-size: 16px;">Live</div>

                        <div class="row">
                            <div class="col-8 text-left">
                                <h2>{{event.title}}</h2>
                            </div>
                            <div class="col-4 text-right">
                                <img :src="'https://dueacademy.s3.amazonaws.com/assets/logos/'+company_id+'_logo.png'" width="100px" alt="" class="img-fluid">
                            </div>
                        </div>
                        
                        <h4>{{event.description}}</h4>
                        <h4 class="mb-0"><b>{{company.short_name}}</b> {{event.date_start_format}}</h4>
                        <!-- <a href="#" class="btn-video float-right"><img src="/images/icon142.png" alt="icon" class="play-bttn"></a> -->
                    </div>
                     
                </div>
            </div>
            <!-- <img src="https://via.placeholder.com/1200x670.png" alt="banner" class="banner-img"> -->

            <div v-if="view_authorized">
                <div class="row" v-if="event.embed_url">
                    <div class="col-md-7 text-left mt-4">
                        <div style="padding: 56.25% 0 0 0; position:relative;" class="banner-img center mt-4">
                            <iframe :src="event.embed_url + '?showTitle=0&showDescription=0&showHighlights=0&showRelated=0&showCountdown=1&market=smb&showDocuments=0&showIndex=0&showDonations=0&autoplay=1'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
                        </div>
                    </div>
                    <div class="col-md-4 text-left mt-4 mr-5">
                        <form class="form-div" style="height: 250px;">
                            <h3>Envía tus preguntas:</h3>
                            <div class="form-group">
                                <textarea class="form-control" v-model="question" type="text" placeholder="Ingresar Pregunta..." style="width: 100%; color: black; font-size: 16px;" rows="8"></textarea>
                            </div>
                            <div class="form-group button-block text-center">
                                <button type="button" @click="submitQuestion" class="form-btn">Enviar</button>
                            </div>
                            <div :class="'text-'+question_fg">{{question_status}}</div>
                        </form>
                    </div>
                </div>
                <div v-else>
                    <h3><strong>Stream No Disponible</strong></h3>
                </div>
            </div>
        </div>
        <!-- banner wrapper -->

        


        <!-- Button trigger modal -->
        <button type="button" class="btn btn-primary d-none" id="openModal" data-toggle="modal" data-target="#userInputModal">
        Open Modal
        </button>

        <!-- Modal -->
        <div class="modal fade" id="userInputModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="userInputModalLabel" aria-hidden="true" style="z-index: 10000000;">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header d-none">
                        <!-- <h5 class="modal-title" id="userInputModalLabel">Modal title</h5>
                         -->
                         <button type="button" class="btn btn-secondary d-none" id="closeModal" data-dismiss="modal">Close Modal</button>
                    </div>
                    <div class="modal-body">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="form-div text-center">
                                    <h2>{{event.type}}</h2>
                                    <p>Por favor ingresa la información</p>
                                    <form>
                                        <div class="form-group mt-2">
                                            <input v-if="emailFg" class="form-control" v-model="email" type="email" placeholder="Correo Electrónico">
                                        </div>
                                        <div class="form-group mt-2">
                                            <input v-if="password_flag" class="form-control" v-model="password_input" type="password" :placeholder="'Contraseña de '+event.type">
                                        </div>
                                        <div class="form-group button-block text-center">
                                            <button type="button" @click="submitData" class="form-btn">Ingresar a {{event.type}}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import axios from 'axios'
    import $ from 'jquery';

    export default {
        created() {
            // User Viewer Stat 'stop'
            window.addEventListener('beforeunload', this.onUnload);
        },
        beforeDestroy() {
            window.removeEventListener('beforeunload', this.onUnload)
        },
        data: function(){
            return {
                app_url: process.env.VUE_APP_URL,
                event: {
                    // id: 1,
                    // company_id: 2,
                    // title: "Prioridades En La Atención Médica En Infecciones Respiratorias Módulo 1 de 4",
                    // description: "Prof. Titular Dr. Gerardo López Pérez. Curso Avalado Por El Instituto Nacional De Pedriatría, Adicionalmente Otorga Puntos De Recertificación Para Médico General (Conamege)",
                    // type: "Curso",
                    // specialty: 25,
                    // venue: "web",
                    // city: "CDMX",
                    // state: "7",
                    // date_start: "2020-04-16 19:00:00",
                    // date_available: "2020-04-16",
                    // started_at: "2020-04-16 19:00:00",
                    // ended_at: "2020-04-16 21:00:00",
                    // category: null,
                    // slug: "armstrong-prioridades-1",
                    // status: 1,
                    // activity_id: 1,
                    // created_by: 1,
                    // created_at: null,
                    // updated_at: null,
                    // date_start_format: "Apr 16, 2020 19:00",
                    // company: {
                    //     id: 2,
                    //     full_name: "Armstrong Laboratorios",
                    //     short_name: "Armstrong",
                    //     academy_name: "Instituto Médico Armstrong",
                    //     academy_name_short: "IMA",
                    //     tax_id: "ALM780206E75",
                    //     email: "ima@lab-armstrong.com.mx",
                    //     contact_email: "ima@lab-armstrong.com.mx",
                    //     contact_phone: "5530001500",
                    //     billing_email: "ima@lab-armstrong.com.mx",
                    //     phone: "5530001500",
                    //     address: "Periferico Sur 4605, Jardines del Pedregal de San Ángel 04500, Coyoacán, CDMX",
                    //     zipcode: "04500",
                    //     state: 7,
                    //     country: 153,
                    //     theme: "default01",
                    //     slug: "armstrong",
                    //     created_at: "2020-06-01T04:11:58.000000Z",
                    //     updated_at: "2020-06-01T04:11:58.000000Z",
                    // }
                },
                company: [],
                company_id: 'sample',
                viewerData: null,
                email: false,
                emailFg: false,
                showModal: true,
                current_url: '#',
                password: null,
                password_input: null,
                password_flag: false,
                view_authorized: false,
                question: null,
                question_fg: null,
                question_status: null
            }
        },
        methods: {
            get_event_info(){
                axios.get(this.app_url+'/dxl/events/'+this.$route.params.eventslug)
                        .then( (response) => {
                            if(response.data.status){
                                // console.log("**YES!");
                                // console.log(response.data);
                                this.event = response.data.message[0];
                                this.company = this.event.company;
                                this.company_id = this.event.company_id;
                                this.password_flag = this.event.password_flag;
                                this.password = this.event.password;
                                // console.log(this.company.id);

                                // Open Email Modal
                                this.emailMethod();

                                axios.get('https://api.ipify.org/?format=json')
                                    .then( (response) => {
                                        this.viewerData = response.data;
                                        // User Viewer Stat 'start'
                                        this.onLoad();
                                    })
                                    .catch( (error) => {
                                        console.log('*error: IPify Viewer Data. '+error);
                                    });
                            } else {
                                // console.log("Event doesn't exitst.");
                                window.location.href = "/";
                            }
                            
                        })
                        .catch( (error) => {
                            console.log(error);
                        });
            },
            onLoad(){
                let data = new FormData();
                data.append('user_email', this.email);
                data.append('event_id', this.event.id);
                data.append('device', 'edge');
                data.append('device_type', 'desktop');
                data.append('ip_from', this.viewerData.ip);
                data.append('action', 'start'); 
                data.append('time', performance.now());
                navigator.sendBeacon('https://ima.dueacademy.com/api/viewer-stats', data);
            },
            onUnload(){
                // var user_email = '-';
                let data = new FormData();
                data.append('user_email', this.email);
                data.append('event_id', this.event.id);
                data.append('device', 'edge');
                data.append('device_type', 'desktop');
                data.append('ip_from', this.viewerData.ip);
                data.append('action', 'end');
                data.append('time', performance.now());
                navigator.sendBeacon('https://ima.dueacademy.com/api/viewer-stats', data);
            },
            emailMethod(){
                this.email = new URL(location.href).searchParams.get('email');

                if(this.email == null){
                    this.emailFg = true;
                } else {
                    this.emailFg = false;
                }
                
                // Show/Hide Input Data Modal
                if(this.emailFg || this.password_flag){
                    $('#openModal').click();
                } else {
                    this.showVideo();
                }

                this.view_authorized = false;
                // this.currentUrl = window.location.pathname;
            },
            showVideo(){
                this.view_authorized = true;
                this.onLoad();
            },
            submitData(){
                var fg = false;
                var msg = '';
                if(this.emailFg){
                    if(this.email==null || this.email == ''){
                        msg += '- Ingrese su Correo\n';
                    } else {
                        fg = true;
                    }
                }

                // Check if password is required for event
                if(this.password_flag){
                    if(this.password_input=='' || this.password_input==null){
                        msg += '- Ingrese Contraseña\n';
                        fg = false;
                    } else {
                        if(this.password_input == this.password){
                            if(this.email==null || this.email == ''){
                                fg = false;
                            } else {
                                fg = true;
                            }
                        } else {
                            msg += '- Contraseña Inválida\n';
                            fg = false;
                        }
                    }
                }

                // Check If Email & Password ok
                if(fg){
                    this.showVideo();
                    $('#closeModal').click();
                } else {
                    alert(msg);
                }
            },
            submitQuestion(){
                console.log(this.app_url+'/dxl/events/'+this.$route.params.eventslug+'/questions/create');
                axios.post(this.app_url+'/dxl/events/'+this.$route.params.eventslug+'/questions/create', {
                            email: this.email,
                            question: this.question
                        })
                        .then( (response) => {
                            if(response.data.status){
                                // console.log("**YES!");
                                console.log(response.data);
                                this.question_status = 'Pregunta Enviada';
                                this.question_fg = 'success';
                                this.question = '';
                            } else {
                                // console.log("Event doesn't exitst.");
                                this.question_status = 'Error. Intentar más tarde.';
                                this.question_fg = 'danger';
                            }
                            
                        })
                        .catch( (error) => {
                            console.log(error);
                        });
            }
        },
        mounted(){
            this.get_event_info();

            // $(this.$refs.vuemodal).on("hidden.bs.modal", this.doSomethingOnHidden)
            // window.onload = function() {
            //     let data = new FormData();
            //     data.append('user_email', user_email);
            //     data.append('event_id', this.event.id);
            //     data.append('device', 'edge');
            //     data.append('device_type', 'desktop');
            //     data.append('ip_from', viewerData.ip);
            //     data.append('action', 'start'); 
            //     data.append('time', performance.now());
            //     navigator.sendBeacon('https://ima.dueacademy.com/api/viewer-stats', data);
            // };
            // window.onunload = function() {
            //     let data = new FormData();
            //     data.append('user_email', user_email);
            //     data.append('event_id', this.event.id);
            //     data.append('device', 'edge');
            //     data.append('device_type', 'desktop');
            //     data.append('ip_from', this.viewerData.ip);
            //     data.append('action', 'end');
            //     data.append('time', performance.now());
            //     navigator.sendBeacon('https://ima.dueacademy.com/api/viewer-stats', data);
            // };
        }
    }
</script>

<style>
.center {
  margin: auto;
  width: 50%;
  /* border: 3px solid #b53940; */
  padding: 10px;
}
</style>