<template>
    <div>
        <section class="form-wrapper">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-sm-5">
                        <div class="form-div text-center">
                            <h2>404</h2>
                            <p>Esta página no se encontró.</p>
                            <form action="#">                                 
                                <div class="form-group button-block text-center">
                                  <a href="/" class="btn btn-primary">Regresa a Home</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style>
.center {
  margin: auto;
  width: 50%;
  /* border: 3px solid #b53940; */
  padding: 10px;
}
</style>